import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
  standalone: true
})
export class FormatDatePipe implements PipeTransform {

  transform(date: number | Date | string): any {
    const date2 = Date.now();
    let diffInMS: number;

    if (typeof date === 'number') {
      diffInMS = date2 - date;
    } else {
      diffInMS = date2 - new Date(date).getTime();
    }

    const diffInHours = diffInMS / 1000 / 60 / 60

    if (diffInHours < 1) {
      return 'Few minutes ago';
    } else if (diffInHours <= 24) {
      return `${Math.ceil(diffInHours)} hours ago`;
    } else if (diffInHours > 24 && diffInHours <= 48) {
      return `Yesterday at ${new Date(date).toLocaleTimeString('en-US')}`
    } else {
      return new DatePipe('en-US').transform(date, "short");
    }
  }
}
