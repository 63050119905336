import { Injectable, effect } from '@angular/core';
import { Observable, from, mergeMap, of } from 'rxjs';
import { MODALS } from '../constants/Modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProblemService } from './problem.service';
import { Domains } from '../shared/models/domain-problems/Domains';
import { Player } from '../shared/models/player/Player';
import ComputePayloadHelper from '../helpers/compute-payload.helper';
import { SignalsService } from './signals.service';

@Injectable({
  providedIn: 'root'
})
export class PlaygroundService {

  domains: Domains[] = [];
  onlinePlayers: Player[] = []
  
  constructor(
    private modal: NgbModal,
    private problemService: ProblemService,
    private signalService: SignalsService
  ) { 
    effect(() => {
      this.onlinePlayers = this.signalService.onlinePlayers().map(o => new Player(o));
    });
  }

  startGame(player?: Player): Observable<any> {
    if (player) {
      const invitedPlayer = this.onlinePlayers.find(op => op.id === player.id);
      invitedPlayer?.select();
    }

    const modalRef = this.modal.open(MODALS['createGameDialog'], { backdrop: 'static' });
    modalRef.componentInstance.domains = this.problemService.domains;
    modalRef.componentInstance.players = this.onlinePlayers;
    return from(modalRef.result).pipe(
      mergeMap((data: any) => {
        if (Object.keys(data).length) {
          const payload = ComputePayloadHelper.startGame(data);
          return this.problemService.startNewGame(payload);
        } else {
          return of(null);
        }
      })
    )
  }
}
